.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.text-block {
  user-select: none;
}

.width-max {
  width: 100%;
}

.bc-style {
  background-color: #cccccc;
}


.tag-container {
  /* border: 4px solid #ee0c21; */

  /* min-height: 100vh; */
  display: flex;
  flex-wrap: wrap;
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
}

.flex-container-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-container__column {
  flex-direction: column;
}

.flex-container__center{
  align-items: center;
  justify-content: center;
}

.flex-container_space-between {
  justify-content: space-between;
}

.flex-container__right{
  align-items: right;
  justify-content: right;
}



.tag {
  border: 2px solid #888888;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 2px;
  text-align: center;
  background: #ffffff;
  color: #351b3d;

  display: flex;
  transition: transform 250ms, opacity 400ms;
  box-shadow: 0 2px 2px hsl(0deg 0% 0% / 0.3);
}

.tag:hover {
  /* transform: scale(1.2); */
  transition: transform 125ms;
  transform: translateY(-2px);
}

.tag__name {
  font-size: 16px;
  cursor: pointer;
}

.tag__name__notSelected {
  font-size: 16px;
  color: #888888;
  cursor: pointer;

  text-decoration: line-through;
}

.selectable-tag__name {
  font-size: 20px;
  cursor: pointer;
}

.selectable-tag__name__notSelected {
  font-size: 18px;
  color: #888888;
  cursor: pointer;

  text-decoration: line-through;
}

.tag__delete {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1em;
}

.tag__delete:hover {
  transition: transform 125ms;
  transform: translateY(-2px);
  color: rgb(170, 27, 27);
}


.tag__save {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1em;
  color: rgb(170, 27, 27);
  transition: transform 250ms, opacity 400ms;
  
}

.tag__save:hover {
  /* box-shadow: 0 2px 2px hsl(0deg 0% 0% / 0.3); */
  transition: transform 125ms;
  transform: translateY(-2px);
}


.tag__add {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1em;
}

.tag__add:hover {
  color: rgb(10, 143, 17);
}

.tag__edit {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1em;
}
.color-green {
  color: rgb(10, 143, 17);
}

.color-red {
  color: rgb(170, 27, 27);
}

.tag__edit:hover {
  /* box-shadow: 0 2px 2px hsl(0deg 0% 0% / 0.3); */
  transition: transform 125ms;
  transform: translateY(-2px);
  color: rgb(10, 143, 17);
}

.tag__visible {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1em;
}

.tag__visible:hover {
  transition: transform 125ms;
  transform: translateY(-2px);
  color: rgb(56, 88, 192);
}

.wifi {
  border: 0px solid #888888;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 2px;
  text-align: center;
  background: #ffffff;
  color: #351b3d;

  display: flex;
}


.icon__info {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1em;
  color: rgb(170,170,170);
}

.icon__info:hover {
  color: rgb(97, 145, 216);
}

.icon__retry {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1em;
  color: rgb(255, 255, 255);
}

.icon__retry:hover {
  color: rgb(0, 0, 0);
}

/* .icon__delete {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1em;
  color: rgb(255, 255, 255);
}

.icon___delete:hover {
  color: rgb(97, 145, 216);
} */


.tooltip-container{
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.file {
  cursor: pointer;
  user-select: none;

}

.file:hover {
  background-color: #b0b0b0;
}

.selected_file {
  cursor: pointer;
  user-select: none;
  background-color: #DCEAB2;
}

.selected_file:hover {
  background-color: #b0b0b0;
}

.folder {
  user-select: none;
  cursor: pointer;

  border: 2px solid rgba(0, 0, 0, 0.54);
  background-color: white;
  border-radius: 5px;
}

.folder:hover {
  color: white;
  background-color: rgba(97, 99, 113, 0.75);
}

.spinner {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.onlist__edit {
  color: rgb(10, 143, 17);
}

.onlist__edit:hover {
  transition: transform 250ms;
  opacity: 0.5;
}

.onDelete_trash {
  color: rgb(170, 27, 27);
}

.onDelete_trash:hover {
  transition: transform 250ms;
  opacity: 0.5;
}

.onCheck {
}

.onCheck:hover {
  transition: transform 250ms;
  opacity: 0.5;
}

.on_close {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  margin: 0px;
  padding-left: 11px;
  padding-right: 11px;
  color: white;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

.on_close:hover {
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  border-color: white;
  /*transition: transform 250ms;*/
  /*transform: scale(1.2);*/
}

.on_close:active {
  background-color: white;
  color: black;
  /*transition: transform 250ms;*/
  /*transform: scale(1.2);*/
}

.row_visible {
  transition: all 0.5s ease-in-out 0.5s;
}


/* Triangle Progress bar */
.triangleProgressBar_amount {
  position: relative;
  height: 100%;
  width: 0%;
  bottom: 0;
  left: 0;
  transition: width 0.3s;
  background: tomato;
}

.triangleProgressBar_tri_backgroundImage {
  background-image: repeating-linear-gradient(
          90deg,
          #e9ecef,
          #e9ecef 5px,
          gray 5px,
          gray 10px
  );
}

.triangleProgressBar_tri_background {
  background: #e9ecef;
}

.triangleProgressBar_tri {
  position: relative;
  height: 60px;
  width: 600px;
  display: flex;
  align-content: flex-start;
}
.triangleProgressBar_tri:before,
.triangleProgressBar_tri:after {
  content: "";
  position: absolute;
  border-left: 600px solid white;
  left: 0;
  z-index: 8;
}
.triangleProgressBar_tri:before {
  border-top: 30px solid transparent;
  top: 50%;
}
.triangleProgressBar_tri:after {
  border-bottom: 30px solid transparent;
  left: 0;
}

.triangle-container {
  display: inline-block;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}


.custom-integration-tab .nav-link.active{
  background-color: rgba(170, 170, 170, 0.5) !important;
  font-weight: bold; /* Change this to your desired color */
}